<template>
  <v-container class="d-block mt-n16">
    <v-row
      class="justify-space-around align-center my-6 mt-n8 text-center primary--text"
    >
      <v-col lg="4">
        <p>{{ $t("first_name") }}</p>
        <h2 class="primary--text">{{ data.first_name }}</h2>
      </v-col>
      <v-col lg="4">
        <p>{{ $t("last_name") }}</p>
        <h2>{{ data.last_name }}</h2>
      </v-col>
    </v-row>
    <v-divider class="my-8"></v-divider>
    <exams
      @getData="getData($event)"
      :data="examsData"
      :loader="examsLoader"
      :id="id"
    />
  </v-container>
</template>
<script>
import { Users as Student } from "@/store/teacher/students/index";
import { Exams } from "@/store/teacher/exams";
import exams from "../exams/index.vue";
export default {
  components: {
    exams,
  },
  data() {
    return { Student, Exams };
  },
  methods: {
    getData(data) {
      Object.assign(data, { student_id: this.id });
      Exams.get(data);
    },
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    data() {
      return Student.tableData.showData;
    },
    examsData() {
      return Exams.tableData;
    },
    examsLoader() {
      return Exams.getState.loading;
    },
  },
  mounted() {
    Student.show(this.id);
    Exams.get({ student_id: this.id });
  },
};
</script>
<style lang="scss" scoped>
h1,
h2 {
  font-weight: 900;
}

p {
  font-weight: 600;
}
</style>
